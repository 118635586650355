import React, { useState, useEffect } from 'react';
import api from '../../axios/api';
import '../../assets/NewFiles/Css/Dashboard.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateUser = () => {
  const [formData, setFormData] = useState({
    userName: '',
    userEmail: '',
    password: '',
    role: 'user',
  });

  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await api.get('/api/admin/getUsers');
        const adminsData = response.data.Users.filter(user => user.role === 'admin');
        setAdmins(adminsData);
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    };

    fetchAdmins();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (!token) {
      toast.error('Unauthorized: No token found');
      return;
    }

    try {
      await api.post(
        '/api/admin/createUser',
        {
          ...formData,
          userEmail: formData.userEmail.toLowerCase(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('User created successfully');
      setFormData({
        userName: '',
        userEmail: '',
        password: '',
        role: 'user',
      });
    } catch (error) {
      console.error('Error creating user:', error);
      toast.error('Failed to create user');
    }
  };

  return (
    <>
      <div className="create-user-container">
        <ToastContainer />
        <h2>Create New User</h2>
        <form onSubmit={handleSubmit} className="create-user-form">
          <div className="form-group">
            <label htmlFor="userName">Name</label>
            <input
              type="text"
              id="userName"
              name="userName"
              value={formData.userName}
              onChange={handleChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="userEmail">Email</label>
            <input
              type="email"
              id="userEmail"
              name="userEmail"
              value={formData.userEmail}
              onChange={handleChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Role</label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
              className="form-select"
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <button type="submit" className="submit-button">Create User</button>
        </form>
      </div>
      <div className="admin-table-container">
        <table className="users-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Verified Trees</th>
              <th>Last Login</th>
              <th>Total Posts</th>
              <th>Geolocated Count</th>
              <th>Role</th>
              <th>Deleted Posts</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin._id}>
                <td>{admin.userName}</td>
                <td>{admin.userEmail}</td>
                <td>{admin.verifiedTrees}</td>
                <td>{new Date(admin.lastLogin).toLocaleString()}</td>
                <td>{admin.totalTrees}</td>
                <td>{admin.geoLocatedTreesCount}</td>
                <td>{admin.role}</td>
                <td>{admin.deletedTrees}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CreateUser;
