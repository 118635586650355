import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaFileAlt, FaUser, FaCheckCircle } from 'react-icons/fa'; // Import required Font Awesome icons
import BlogCard from '../BlogCard';
import ReactLoading from 'react-loading';
import "../../assets/NewFiles/Css/searchcomponent.css";

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    treeName: false,
    uploadedBy: false,
    location: false,
  });
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/upload/all-images`);
        setData(response.data.images);
        setStats(response.data.stats[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters({
      ...filters,
      [name]: checked,
    });
  };

  const filteredData = data.filter((item) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    console.log(item);
    return (
      (!filters.treeName || item.treeName.toLowerCase().includes(lowerSearchTerm)) &&
      (!filters.uploadedBy || item.userId.userName.toLowerCase().includes(lowerSearchTerm)) &&
      (!filters.location || item.address.toLowerCase().includes(lowerSearchTerm))
    );
  });

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <ReactLoading type="spin" color="#8BC34A" height={50} width={50} />
      </div>
    );
  }

  return (
    <div className="search-component">
      <div className="filters">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleInputChange}
          className="search-input"
        />
        
        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              name="treeName"
              checked={filters.treeName}
              onChange={handleCheckboxChange}
              className="checkbox-input"
            />
            Tree Name
          </label>
          <label>
            <input
              type="checkbox"
              name="uploadedBy"
              checked={filters.uploadedBy}
              onChange={handleCheckboxChange}
              className="checkbox-input"
            />
            Uploaded By
          </label>
          <label>
            <input
              type="checkbox"
              name="location"
              checked={filters.location}
              onChange={handleCheckboxChange}
              className="checkbox-input"
            />
            Location
          </label>
        </div>
    
      </div>
      <div style={{ display: 'flex', flexDirection: 'row',marginLeft:'100px' }}>
      <div style={{display:'flex',alignItems: 'center', margin: '10px' }}>
        <FaFileAlt style={{ marginRight: '10px', fontSize: '24px' }} />
        <span style={{ fontSize: '18px' ,color:"black"}}>Total Plants: {stats.totalTrees || 0}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
        <FaCheckCircle style={{ marginRight: '10px', fontSize: '24px' }} />
        <span style={{ fontSize: '18px' ,color:"black"}}>Verified Plants: {stats.verifiedTrees || 0}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
        <FaUser style={{ marginRight: '10px', fontSize: '24px' }} />
        <span style={{ fontSize: '18px' ,color:"black"}}>Total Users: {stats.totalUsers || 0}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
        <FaCheckCircle style={{ marginRight: '10px', fontSize: '24px' }} />
        <span style={{ fontSize: '18px',color:"black" }}>Verified Users: {stats.verifiedUsers || 0}</span>
      </div>
    </div>
      <BlogCard data={filteredData} />  
    </div>
  );
};

export default SearchComponent;

const styles = {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }
};
