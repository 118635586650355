import React from 'react'

import '../../assets/css/bootstrap.min.css'
import '../../assets/css/all.min.css'
import '../../assets/css/swiper-bundle.min.css'
import '../../assets/css/magnific-popup.css'
import '../../assets/css/animate.css'
import '../../assets/css/nice-select.css'
import '../../assets/css/style.css'


const Banner = () => {
    return (
        <div>
            <section className="bg-image pt-60 pb-60">
                <div className="container">
                    <h2 className="wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">Tree Metric</h2>
                </div>
            </section>
        </div>
    )
}

export default Banner
