import { useNavigate } from "react-router-dom";
import '../../assets/css/bannerTitle.css';

const BannerTitle = () => {
    const navigate = useNavigate();
    return (
        <div id="bannerTitle">
            <h4
                className="wow fadeInUp text-white gradient-color"
                data-wow-duration="1.2s"
                data-wow-delay=".2s"
                style={{ fontWeight: 'bold' }}
            >
                Tree<span className="gradient-color-green">Tagging</span>, for a  <span className="gradient-color-green">Greener</span> Legacy
            </h4>
            <h1
                className="wow fadeInUp text-white"
                data-wow-duration="1.4s"
                data-wow-delay=".4s"
            >
                We Plan Tomorrow for <br />
                better <span className="primary-color gradient-color">Today</span>
            </h1>
            <div className="wow fadeInUp" data-wow-duration="1.6s" data-wow-delay=".6s">
                <p className="text-white">
                    {/* ENCORAA ESG offers a 360 degree end to end support and management of carbon assets */}
                    <span className="primary-color gradient-color text-lg">Tree Metric</span>, Tree Tracking & Verification for a Sustainable Tomorrow for a Greener Legacy
                </p>
            </div>
            <div className='titleButtons'>
                <button data-wow-duration="1.5s"
                    data-wow-delay=".5s" onClick={() => { navigate('/upload') }}>
                    <span className="text-white">Upload Tree  </span>
                    <i className="fa-solid fa-angles-right"></i>
                </button>
                <button data-wow-duration="1.5s"
                    data-wow-delay=".5s" onClick={() => { navigate('/trees') }}>
                    <span className="text-white">View All Tree</span>
                    <i className="fa-solid fa-angles-right"></i>
                </button>
            </div>
        </div>
    )
}

export default BannerTitle