import React from 'react'

import SearchComponent from '../components/features/SearchComponent'

const Search = () => {
  return (
    <div>
      <section className="bg-image pt-40 pb-40">
            <div className="container">
                <h2 className="wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">Trees</h2>
               
            </div>
        </section>
        <SearchComponent/>
    </div>
  )
}

export default Search