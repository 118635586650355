import React, { useState } from 'react';

import VerifyPosts from '../components/dashboard/VerifyPosts';
import CreateUser from '../components/dashboard/CreateUser';
import ViewUsers from '../components/dashboard/ViewUsers';
import Stats from '../components/dashboard/Stats';
import UpdateGallery from '../components/dashboard/UpdateGallery';

import '../assets/NewFiles/Css/Dashboard.css';

const Dashboard = () => {
  const [activeMenu, setActiveMenu] = useState('stats');

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <button
          className={activeMenu === 'stats' ? 'active' : ''}
          onClick={() => setActiveMenu('stats')}
        >
          Dashboard
        </button>
        <button
          className={activeMenu === 'verifyPosts' ? 'active' : ''}
          onClick={() => setActiveMenu('verifyPosts')}
        >
          Verify Trees
        </button>
        <button
          className={activeMenu === 'createUser' ? 'active' : ''}
          onClick={() => setActiveMenu('createUser')}
        >
          Create User
        </button>
        <button
          className={activeMenu === 'viewUsers' ? 'active' : ''}
          onClick={() => setActiveMenu('viewUsers')}
        >
          View Users
        </button>
        <button
          className={activeMenu === 'updateGallery' ? 'active' : ''}
          onClick={() => setActiveMenu('updateGallery')}
        >
          Update Gallery
        </button>
      </div>
      <div className="content">
        {activeMenu === 'verifyPosts' && <VerifyPosts />}
        {activeMenu === 'createUser' && <CreateUser />}
        {activeMenu === 'viewUsers' && <ViewUsers />}
        {activeMenu === 'stats' && <Stats />}
        {activeMenu === 'updateGallery' && <UpdateGallery />}
      </div>
    </div>
  );
};

export default Dashboard;
