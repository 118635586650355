import React, { useEffect, useState } from 'react';
import api from '../../axios/api';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import {  FaCheckCircle, FaFileAlt, FaGlobe, FaThumbsUp, FaTrash, FaUsers } from 'react-icons/fa';
import '../../assets/NewFiles/Css/Stats.css'


const StatsComponent = () => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    // Mock API call
    const fetchStats = async () => {
      try {
        const response = await api.get('/api/admin/stats');
        setStats(response.data.stats[0]);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  if (!stats) {
    return <div>Loading...</div>;
  }

  const userData = {
    labels: ['Total Users', 'Verified Users'],
    datasets: [
      {
        data: [stats.totalUsers, stats.verifiedUsers],
        backgroundColor: ['#FF6384', '#36A2EB'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  const postData = {
    labels: ['Trees','Verified Trees','Trees with geolocation'],
    datasets: [
      {
        data: [stats.totalTrees,stats.verifiedTrees,stats.geoLocatedTreesCount],
        backgroundColor: ['#FF6384','#36A2EB','#FFCE56'],
      }
    ],
  };



  return (
    <div>
      <h1 style={{ display: 'flex', justifyContent: 'space-around' ,marginBottom: '50px'}}>Statistics</h1>

      <div className="stats-boxes">
        <div className="stat-box">
          <FaUsers />
          <h3>Total Users</h3>
          <p>{stats.totalUsers}</p>
        </div>
        <div className="stat-box">
          <FaCheckCircle  />
          <h3>Verified Users</h3>
          <p>{stats.verifiedUsers}</p>
        </div>
        <div className="stat-box">
          <FaFileAlt  />
          <h3>Total Trees</h3>
          <p>{stats.totalTrees}</p>
        </div>
      </div>
      <div className="stats-boxes">
        <div className="stat-box">
          <FaGlobe  />
          <h3>Trees with Geolocation</h3>
          <p>{stats.geoLocatedTreesCount}</p>
        </div>
        <div className="stat-box">
          <FaThumbsUp   />
          <h3>Verified Trees</h3>
          <p>{stats.verifiedTrees}</p>
        </div>
        <div className="stat-box">
          <FaTrash />
          <h3>Deleted Trees</h3>
          <p>{stats.deletedTrees}</p>
        </div>
      </div>


      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div>
          <h2>User Stats</h2>
          <Pie data={userData} />
        </div>

        <div>
          <h2>Tree Stats</h2>
          <Pie data={postData}  />
        </div>
      </div>
    </div>
  );
};

export default StatsComponent;
