import React, { useState, useEffect } from 'react';
import api from '../../axios/api';
import '../../assets/NewFiles/Css/Dashboard.css';

const ViewAllUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/api/admin/getUsers');
        setUsers(response.data.Users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const filteredUsers = users.filter(user => user.role === 'user');

  return (
    <div className="view-all-users-container">
      <h2>View All Users</h2>
      <table className="users-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Verified Trees</th>
            <th>Last Login</th>
            <th>Total Posts</th>
            <th>Geolocated Count</th>
            <th>Role</th>
            <th>Deleted Posts</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user._id}>
              <td>{user.userName}</td>
              <td>{user.userEmail}</td>
              <td>{user.verifiedTrees}</td>
              <td>{new Date(user.lastLogin).toLocaleString()}</td>
              <td>{user.totalTrees}</td>
              <td>{user.geoLocatedTreesCount}</td>
              <td>{user.role}</td>
              <td>{user.deletedTrees}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewAllUsers;
