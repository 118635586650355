import React, { useState } from "react";
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/all.min.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/magnific-popup.css';
import '../../assets/css/animate.css';
import '../../assets/css/nice-select.css';
import '../../assets/css/style.css';

import contactData from '../../assets/contactData';
import api from '../../axios/api';

import { ToastContainer, toast } from "react-toastify";

const Form = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Select Subject');
  const [name, setName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [message, setMessage] = useState('');

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      contactNumber,
      email,
      organization,
      subject: selectedOption,
      message,
      contactMailEncoraa:contactData.email.title,
    };

    try {
      const response = await api.post('/api/user/sendContactForm', formData);

      if (response.status === 200) {
        toast.success('Your message has been sent successfully.');
        // Clear the form fields
        setName('');
        setContactNumber('');
        setEmail('');
        setOrganization('');
        setSelectedOption('Select Subject');
        setMessage('');
      } else {
        alert('Failed to send your message. Please try again.');
      }
    } catch (error) {
      console.error('Error sending the contact form:', error);
      toast.error('An error occurred while sending your message. Please try again.');
    }
  };

  return (
    <div>
      <ToastContainer/>
      <section className="bg-image pt-40 pb-40">
        <div className="container">
          <h2 className="wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
            Contact Us
          </h2>
        </div>
      </section>

      <section className="contact pt-60 pb-60">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6">
              <div className="content bg-image">
                <h2>
                  Have something in mind? <br />
                  Let's talk.
                </h2>
                <div className="arry">
                  <img src="/images/icon/arry.png" alt="" />
                </div>
                <ul>
                  <li>
                    <a href={contactData.address.href} target="_blank" rel="noreferrer">
                      <i className="fa-solid fa-location-dot"></i> {contactData.address.title}
                    </a>
                  </li>
                  <li>
                    <a href={contactData.phone.href}>
                      <i className="fa-solid fa-phone-volume"></i>{contactData.phone.title}
                    </a>
                  </li>
                  <li>
                    <a href={contactData.email.href} target="_blank" rel="noreferrer">
                      <i className="fa-solid fa-envelope"></i>{contactData.email.title}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-area">
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Contact Number"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Organization (optional)"
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                  />
                  <select name="subject" id="subject" tabIndex={0} style={{ display: 'none' }}>
                    <option value="0">Select Subject</option>
                    <option value="Account">Account</option>
                    <option value="Service">Service</option>
                    <option value="Pricing">Pricing</option>
                    <option value="Support">Support</option>
                  </select>

                  <div className={`nice-select ${dropdownOpen ? 'open' : ''}`} onClick={toggleDropdown} tabIndex={0}>
                    <span className="current">{selectedOption}</span>
                    <ul className="list">
                      <li
                        data-value="Select Subject"
                        className={`option ${selectedOption === "Select Subject" ? 'selected focus' : ''}`}
                        onClick={() => handleOptionSelect("Select Subject")}
                      >
                        Select Subject
                      </li>
                      <li
                        data-value="Account"
                        className={`option ${selectedOption === "Account" ? 'selected focus' : ''}`}
                        onClick={() => handleOptionSelect("Account")}
                      >
                        Account
                      </li>
                      <li
                        data-value="Service"
                        className={`option ${selectedOption === "Service" ? 'selected focus' : ''}`}
                        onClick={() => handleOptionSelect("Service")}
                      >
                        Service
                      </li>
                      <li
                        data-value="Pricing"
                        className={`option ${selectedOption === "Pricing" ? 'selected focus' : ''}`}
                        onClick={() => handleOptionSelect("Pricing")}
                      >
                        Pricing
                      </li>
                      <li
                        data-value="Support"
                        className={`option ${selectedOption === "Support" ? 'selected focus' : ''}`}
                        onClick={() => handleOptionSelect("Support")}
                      >
                        Support
                      </li>
                    </ul>
                  </div>
                  <textarea
                    name="Your Review"
                    id="massage"
                    placeholder="Message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>

                  <button type="submit">
                    Submit
                    <i className="fa-solid fa-arrow-right-long" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Form;
