import React from 'react'
import UploadComponent from '../components/features/UploadComponent'

const Upload = () => {
  return (
    <div>
        <UploadComponent/>
    </div>
  )
}

export default Upload