import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../axios/api';
import {  ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../assets/NewFiles/Css/ForgetPasswordPage.css';

const ForgetPasswordPage = () => {
  const [step, setStep] = useState(1);
  const [userEmail, setuserEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleSendOtp = async () => {
    try {
      const response = await api.post('/api/auth/sendotp', { userEmail });
      if (response.data.message === 'OTP sent successfully') {
        toast.success('OTP sent successfully');
        setStep(2);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Error sending OTP. Please try again.');
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await api.post('/api/auth/verifyotp', { userEmail, otp, newPassword });
      if (response.data.message === 'Password reset successfully') {
        toast.success('Password reset successfully');
        setStep(3);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Error resetting password. Please try again.');
    }
  };
  return (
    <div className="forget-password-container">
      <ToastContainer />
      <div className="forget-password-form">
        <h2>Forget Password</h2>
        {step === 1 && (
          <>
            <div className="forget-form-group">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={userEmail}
                onChange={(e) => setuserEmail(e.target.value)}
                required
              />
            </div>
            <button onClick={handleSendOtp} className="send-otp-button">Send OTP</button>
          </>
        )}
        {step === 2 && (
          <>
            <div className="forget-form-group">
              <label>OTP</label>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
            <div className="forget-form-group">
              <label>New Password</label>
              <input
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <button onClick={handleResetPassword} className="reset-password-button">Reset Password</button>
          </>
        )}
        {step === 3 && <div style={{ fontWeight: 'bold' }}>Password reset successfully!</div>}

        <div className='forget-navigation'>
          <p>Already have an account? </p><Link to="/login" className="option-link">login</Link>
          <p> Or </p><Link to="/Signup" className="option-link">Sign up Here</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
