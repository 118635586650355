import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [address, setAddress] = useState("");
  const [latlng, setLatlng] = useState(null);
  const [country, setCountry] = useState(null);
  const [isLoggedIn,setLoggedIn]=useState(false);
  const [profilePicture,setProfilePicture]=useState(false);
  const [role,setRole]=useState("");
  
  return (
    <GlobalContext.Provider value={{ address, setAddress, latlng, setLatlng ,country, setCountry,isLoggedIn,setLoggedIn,profilePicture,setProfilePicture,role,setRole}}>
      {children}
    </GlobalContext.Provider>
  );
};
