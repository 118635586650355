import React from 'react'

import Banner from '../components/Home/Banner';
import Statistics from '../components/Home/Statistics';


const Home = () => {
  return (
    <div>
      <Banner/>
      <Statistics/>  
    </div>
  )
}

export default Home