import React from 'react';

import '../../assets/css/bootstrap.min.css'
import '../../assets/css/all.min.css'
import '../../assets/css/swiper-bundle.min.css'
import '../../assets/css/magnific-popup.css'
import '../../assets/css/animate.css'
import '../../assets/css/nice-select.css'
import '../../assets/css/style.css'

import BannerTitle from './BannerTitle';
// import video from "../../assets/media/video1.mp4"
// import banner_img from "../../assets/images/banner/plant_bg_tree.jpg"

const Banner = () => {
    
    return (
        <div className="banner1">



            <img src="https://res.cloudinary.com/encoraaprp/image/upload/c_fill,h_960,w_2050/v1719862426/yc7rxjutgzn4qp9rwred.jpg" alt="strock-text" />

            {/* <video  muted className="bg-vid" width="600" height="300">
        <source src={video} type="video/mp4" />
      </video>      */}

            <div className="bg-overlay"></div>
            <div id="light">
                <div id="lineh1"></div>
                <div className="vertline"></div>
                <div id="lineh2"></div>
                <div id="lineh3"></div>
                <div id="lineh4"></div>
                <div className="vertline2"></div>
                <div id="lineh5"></div>
                <div id="lineh6"></div>
                <div id="lineh7"></div>
                <div id="lineh8"></div>
                <div className="vertline3"></div>
                <div id="lineh9"></div>
                <div id="lineh10"></div>
                <div id="lineh11"></div>
                <div className="vertline4"></div>
                <div id="lineh12"></div>
            </div>
            <BannerTitle />
        </div>
    )
};

export default Banner;