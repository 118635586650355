import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TopUsersTable = () => {
  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState({ totalPosts: 0, totalOther: 0, totalUploads: 0 });

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/User/topUsers`);
        setUsers(response.data);

        // Calculate totals
        let totalPosts = 0;
        let totalOther = 0;  // Assuming this is always 0 for now
        let totalUploads = 0;

        response.data.forEach(user => {
          totalPosts += user.totalTrees;
          totalUploads += user.totalTrees;  // Assuming uploads = total_posts
        });

        setTotalCounts({ totalPosts, totalOther, totalUploads });
      } catch (error) {
        console.error('Error fetching top users:', error);
      }
    };

    fetchTopUsers();
  }, []);

  const styles = {
    container: {
      padding: '50px 100px',
      textAlign: 'center',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',
    },
    th: {
      border: '1px solid #ddd',
      padding: '8px',
      backgroundColor: '#f2f2f2',
      fontWeight: 'bold',
      textAlign: 'left',
    },
    td: {
      border: '1px solid #ddd',
      padding: '8px',
      textAlign: 'left',
    },
    tr: {
      cursor: 'initial',
    },
    totalRow: {
      fontWeight: 'bold',
    },
    a: {
      cursor:'initial',
      color: 'black',
      textDecoration: 'none',
    },
    img: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      marginRight: '10px',
    },
  };

  return (
    <div style={styles.container}>
      <h1>By Users</h1>
      <p>By clicking on the user name, you can reach user profile</p>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Profile</th>
            <th style={styles.th}>Name</th>
            <th style={styles.th}>Plants</th>
            <th style={styles.th}>Other</th>
            <th style={styles.th}>Uploads</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index} style={styles.tr}>
              <td style={styles.td}>
                <td style={styles.a}>
                  <img src={user.userProfilePic} alt={user.userName} style={styles.img} />
                </td>
              </td>
              <td style={styles.td}>
                <td
                  style={styles.a}
                 
                >
                  {user.userName}
                </td>
              </td>
              <td style={styles.td}>{user.totalTrees}</td>
              <td style={styles.td}>0</td> {/* Assuming other is always 0 */}
              <td style={styles.td}>{user.totalTrees}</td>
            </tr>
          ))}
          <tr style={styles.totalRow}>
            <td style={styles.td}>Total</td>
            <td style={styles.td}></td>
            <td style={styles.td}>{totalCounts.totalPosts}</td>
            <td style={styles.td}>{totalCounts.totalOther}</td>
            <td style={styles.td}>{totalCounts.totalUploads}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TopUsersTable;
