import React from 'react';
import Form from '../components/contact/Form';

const Contact = () => {
  return (
    <div>
        <Form/>
    </div>
  )
}

export default Contact