import React, { useState, useEffect } from 'react';
import '../../assets/NewFiles/Css/UpdateGallery.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../axios/api';

const PhotoUploadForm = () => {
  const [imageCaption, setImageCaption] = useState('');
  const [imageDescription, setImageDescription] = useState('');
  const [publishFromDate, setPublishFromDate] = useState('');
  const [publishTillDate, setPublishTillDate] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);


  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await api.get("/api/admin/getadmingallery");
        setGallery(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('imageCaption', imageCaption);
    formData.append('imageDescription', imageDescription);
    formData.append('publishFromDate', publishFromDate);
    formData.append('publishTillDate', publishTillDate);

    try {
      const token = localStorage.getItem('token');

      if (!token) {
        toast.error('Unauthorized: No token found');
        return;
      }

      const response = await api.post('/api/admin/uploadGallery', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 500) {
        toast.error('Failed to upload image');
      }

      if (response.status === 201) {
        toast.success('Image upload successful');
        setGallery(prevGallery => [...prevGallery, response.data.image]);
      }

      // Clear form fields
      setImageCaption('');
      setImageDescription('');
      setPublishFromDate('');
      setPublishTillDate('');
      setImageFile(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (index) => {
    const imageId = gallery[index]._id;
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        toast.error('Unauthorized: No token found');
        return;
      }

      const response = await api.delete(`/api/admin/deleteGalleryImage/${imageId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        toast.success('Image deleted successfully');
        setGallery(prevGallery => prevGallery.filter((_, i) => i !== index));
      } else {
        toast.error('Failed to delete image');
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      toast.error('Failed to delete image');
    }
  };

  const handleEdit = (index) => {
    const image = gallery[index];
    setImageCaption(image.imageCaption);
    setImageDescription(image.imageDescription);
    setPublishFromDate(new Date(image.publishFromDate).toISOString().split('T')[0]);
    setPublishTillDate(new Date(image.publishTillDate).toISOString().split('T')[0]);
    setImageFile(null);
    setEditingIndex(index);
  };
  

  const handleSave = async (index) => {
    const imageId = gallery[index]._id;
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('imageCaption', imageCaption);
    formData.append('imageDescription', imageDescription);
    formData.append('publishFromDate', publishFromDate);
    formData.append('publishTillDate', publishTillDate);

    try {
      const token = localStorage.getItem('token');

      if (!token) {
        toast.error('Unauthorized: No token found');
        return;
      }

      const response = await api.put(`/api/admin/updateGallery/${imageId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        toast.success('Image updated successfully');
        setGallery(prevGallery =>
          prevGallery.map((image, i) => (i === index ? response.data.image : image))
        );
        setEditingIndex(null);
        setImageCaption('');
        setImageDescription('');
        setPublishFromDate('');
        setPublishTillDate('');
        setImageFile(null);
      } else {
        toast.error('Failed to update image');
      }
    } catch (error) {
      console.error('Error updating image:', error);
      toast.error('Failed to update image');
    }
  };

  const handleCancel = () => {
    setImageCaption('');
    setImageDescription('');
    setPublishFromDate('');
    setPublishTillDate('');
    setImageFile(null);
    setEditingIndex(null);
  };
  

  return (
    <div>
      <div className="gallery">
        {gallery.map((image, index) => {
          const publishFromDate = new Date(image.publishFromDate).toLocaleDateString();
          const publishTillDate = new Date(image.publishTillDate).toLocaleDateString();

          return (
            <div key={index} className="gallery-item-dashboard">
              <img src={image.imageUrl} alt={image.imageName} />
              <br />
              {editingIndex === index ? (
                <>
                  <input
                    type="text"
                    value={imageCaption}
                    onChange={(e) => setImageCaption(e.target.value)}
                  />
                  <br/>
                  <textarea
                    value={imageDescription}
                    onChange={(e) => setImageDescription(e.target.value)}
                  />
                  <br/>
                  <input
                    type="date"
                    value={publishFromDate}
                    onChange={(e) => setPublishFromDate(e.target.value)}
                  />
                  <br/>
                  <input
                    type="date"
                    value={publishTillDate}
                    onChange={(e) => setPublishTillDate(e.target.value)}
                  />
                  <br/>
                  <input type="file" onChange={handleImageUpload} />
                  <br/>
                  <button onClick={() => handleSave(index)}>Save</button>
                  <button onClick={() => handleCancel(index)}>Cancel</button>
                </>
              ) : (
                <>
                  <p><b>Image Caption: </b>{image.imageCaption}</p><br/>
                  <p><b>Image Description: </b>{image.imageDescription}</p><br/>
                  <p><b>From: </b>{publishFromDate}</p><br/>
                  <p><b>Till: </b>{publishTillDate}</p><br/>
                  <button className="delete-btn" onClick={() => handleDelete(index)}>Delete</button>
                  <button className="edit-btn" onClick={() => handleEdit(index)}>Edit</button>
                </>
              )}
            </div>
          );
        })}
      </div>
      <div className="photo-upload-form">
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Upload Image:</label>
            <input type="file" onChange={handleImageUpload} />
          </div>
          <div className="form-group">
            <label>Image Caption:</label>
            <input
              type="text"
              value={imageCaption}
              onChange={(e) => setImageCaption(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Image Description:</label>
            <textarea
              value={imageDescription}
              onChange={(e) => setImageDescription(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Publish From Date:</label>
            <input
              type="date"
              value={publishFromDate}
              onChange={(e) => setPublishFromDate(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Publish Till Date:</label>
            <input
              type="date"
              value={publishTillDate}
              onChange={(e) => setPublishTillDate(e.target.value)}
            />
          </div>
          <button type="submit">Upload Image</button>
        </form>
      </div>
    </div>
  );
};

export default PhotoUploadForm;
