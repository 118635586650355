  import React from 'react';
  import {FaCheckCircle} from 'react-icons/fa';

  const BlogCard = ({ data }) => {
    const styles = {
      section: {
        paddingTop: '5px',
        paddingBottom: '5px',
        backgroundColor: '#f8f9fa',
      },
      container: {
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0 15px',
      },
      row: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        justifyContent: 'center',
      },
      col: {
        flex: '0 0 23%',
        maxWidth: '23%',
        marginBottom: '2px',
        boxSizing: 'border-box',
        transition: 'transform 0.3s, opacity 0.3s',
        borderRadius: '10px',
        overflow: 'hidden',
      },
      blogCardItem: {
        backgroundColor: '#8BC34A',
        borderRadius: '10px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        overflow: 'hidden',
        transition: 'transform 0.3s, box-shadow 0.3s',
        border: '2px solid #8BC34A',
        height: '530px',
        display: 'flex',
        flexDirection: 'column',
      },
      blogCardImage: {
        width: '100%',
        height: '275px',
      },
      blogCardContent: {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
      blogCardDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0px', // Less space between details
      },
      image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      plantName: {
        width: '200px',
        boxSizing: 'border-box',
        fontSize: '20px',
        fontWeight: 'bold',
        padding: '10px 0',
        color: '#EFEFEF',
        margin: '5px', // Ensure no extra margin
      },
      detail: {
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#333',
        textAlign: 'left',
        wordBreak: 'break-word',
        margin: '0px', // Ensure no extra margin
        marginLeft:'5px'
      },
      verified:{
        fontSize:'20px',
        marginLeft:'5px'
      }
    };

    return (
      <section style={styles.section}>
        <div style={styles.container}>
          <div style={styles.row}>
            {data.map((item, index) => (
              <div key={index} style={styles.col} className="wow fadeInDown" data-wow-duration="1.2s" data-wow-delay=".2s">
                <div style={styles.blogCardItem}>
                  <div style={styles.blogCardImage}>
                    <img src={item.treeImageUrl} alt={item.treNname} style={styles.image} />
                  </div>
                  
                  <span style={styles.plantName}>{item.treeName}</span>                
                    <span style={styles.verified}>
                    {item.isVerified ? <FaCheckCircle color='green'/> : ''}
                    </span>
                    
                    <div style={styles.blogCardDetails}>                      
                      <span style={{fontWeight: 'bold',fontSize: '18px',color: 'black',textAlign: 'left',wordBreak: 'break-word',marginLeft: '5px', }}>Planted by: {item.userId.userName}</span>
                      <span style={styles.detail}>Uploaded On: {new Date(item.createdAt).toDateString()}</span>
                      <span style={styles.detail}>Planted On: {new Date(item.plantedOn).toDateString()}</span>
                      <span style={styles.detail}>Location: {item.address}</span>
                    </div>
                  </div>
                </div>
            
            ))}
          </div>
        </div>
      </section>
    );
  }

  export default BlogCard;