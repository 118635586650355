import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaTrash, FaCheck } from "react-icons/fa";
import api from "../../axios/api";
import { ToastContainer, toast } from 'react-toastify';
import "../../assets/NewFiles/Css/Dashboard.css";

const VerifyPosts = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("latest");
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);


  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await api.get("/api/admin/getPosts");
        setPosts(response.data.images);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleFilter = (e) => setFilter(e.target.value);
  const handleSortOrder = (e) => setSortOrder(e.target.value);

  const handleVerify = async (id) => {
    setIsVerifying(true);
    try {
      await api.patch(`/api/admin/posts/${id}/verify`);
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === id ? { ...post, is_verified: true } : post
        )
      );
      toast.success("Verified");
      const response = await api.get("/api/admin/getPosts");
      setPosts(response.data.images);
      
    } catch (error) {
      console.error("Error verifying post:", error);
    } finally {
      setIsVerifying(false);
    }
  };
  
  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      await api.delete(`/api/admin/posts/${id}`);
      setPosts((prevPosts) => prevPosts.filter((post) => post._id !== id));
      toast.error("Deleted");
    } catch (error) {
      console.error("Error deleting post:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSelectPost = (id) => {
    setSelectedPosts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((postId) => postId !== id)
        : [...prevSelected, id]
    );
  };

  const handleVerifySelected = async () => {
    setIsVerifying(true);
    try {
      await Promise.all(
        selectedPosts.map((id) => api.patch(`/api/admin/posts/${id}/verify`))
      );
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          selectedPosts.includes(post._id)
            ? { ...post, is_verified: true }
            : post
        )
      );
      setSelectedPosts([]);
      toast.success("Verified");
      const response = await api.get("/api/admin/getPosts");
      setPosts(response.data.images);
    } catch (error) {
      console.error("Error verifying selected posts:", error);
    } finally {
      setIsVerifying(false);
    }
  };
  

  const filteredPosts = posts
    .filter(
      (post) =>
        !post.isVerified &&
        post.treeName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        post.userId.userName
          .toLowerCase()
          .includes(filter.toLowerCase())
    )
    .sort((a, b) =>
      sortOrder === "latest"
        ? new Date(b.createdAt) - new Date(a.createdAt)
        : new Date(a.createdAt) - new Date(b.createdAt)
    );

  return (
    <div>
      <ToastContainer/>
      <h2>Verify Trees</h2>
      <div className="controls">

        <input
          type="text"
          placeholder="Search by Plant Name..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-bar"
        />
        <input
          type="text"
          placeholder="Filter by Username..."
          value={filter}
          onChange={handleFilter}
          className="filter-input"
        />
        <select
          value={sortOrder}
          onChange={handleSortOrder}
          className="sort-select"
        >
          <option value="latest">Latest</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>
      <button
        onClick={handleVerifySelected}
        className="verify-selected-button"
        disabled={selectedPosts.length === 0 || isVerifying}
      >
      <FaCheck /> Verify Selected
      </button>

      <div className="posts-grid">
        {filteredPosts.map((post) => (
          <div
            key={post._id}
            className={`post-card ${post.isVerified ? "verified" : ""}`}
          >
            <input
              type="checkbox"
              checked={selectedPosts.includes(post._id)}
              onChange={() => handleSelectPost(post._id)}
              className="select-checkbox"
            />
            <img
              src={post.treeImageUrl}
              alt={post.imageName}
              className="post-image"
            />
            <h3>{post.Plant_name}</h3>
            <p>Planted By: {post.userId.userName}</p>
            <p>Planted On: {new Date(post.plantedOn).toLocaleDateString()}</p>
            <p>Created At: {new Date(post.createdAt).toLocaleDateString()}</p>
            <p className="post-address">{post.address}</p>
            <div className="post-actions">
              <button
                onClick={() => handleVerify(post._id)}
                className={`verify-button ${
                  post.isVerified ? "verified" : ""
                }`}
                disabled={post.is_verified || isVerifying}
              >
                <FaCheckCircle /> {post.isVerified ? "Verified" : "Verify"}
              </button>
              <button
                onClick={() => handleDelete(post._id)}
                className="delete-button"
                disabled={isDeleting}
              >
                <FaTrash /> Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerifyPosts;
